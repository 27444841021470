import React, { Fragment, useState } from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

function WorksList({ data }) {
    const array = data;
    const [showedWorks, setShowedWorks] = useState(array[0])

    const list = () => {
        return showedWorks.map((eles) => {
            const { id, slug, project } = eles?.node
            const { worksTitleEn } = project

            return (
                <li key={id}>
                    <Link to={`/en/works/${slug}/`} className="project-item wow fadeIn">
                        <div className="pic">
                        <div className="img">
                            {project.worksCover ? (
                              <GatsbyImage image={project.worksCover?.localFile?.childImageSharp?.gatsbyImageData} alt="" />
                              ) : (
                              <GatsbyImage image={data.default?.childImageSharp?.gatsbyImageData} alt="" />
                            )}
                        </div>
                        </div>
                        <div className="txt">
                          <h5 className="tit">
                              {worksTitleEn}
                          </h5>
                        </div>
                    </Link>
                </li>
            );
        })
    };

    const [buttonShow, setButtonShow] = useState(array.length > 1);
    const [paged, setPaged] = useState(1);

    const handleLoadMore = () => {
      let index = paged;

      if(index + 1 < array.length) {
        setPaged(paged + 1);
        setButtonShow(true)
        setShowedWorks(showedWorks.concat(array[index]))
      } else {
        setPaged(paged + 1);
        setButtonShow(false);
        setShowedWorks(showedWorks.concat(array[index]))
      }
    }

    return (
      <Fragment>
        <ul className="project-list">
            {list()}
        </ul>
        <button
          onClick={handleLoadMore}
          style={{ cursor: "point" }}
          className={`more-btn s1${
            buttonShow ? "" : " hide"
          }`}
        ></button>
      </Fragment>
    )
}

export default WorksList;